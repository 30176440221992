@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../../../common/styles/abstracts/mixins";
.version-history {
    padding-top: 40px;
    padding-bottom: 40px;

    @include mq("tablet") {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}
