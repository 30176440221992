@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";

footer {
    background: $clr-gray-100;
    @include flex(row, flex-start, center);
    width: 100%;
    .footer-wrapper {
        @include flex(row, flex-start, center);
    }
}

.logo-pcg-footer {
    @include flex(column, flex-start, center);
    height: 48px;
    width: 72px;
    @include mq("tablet-wide", min) {
        height: 64px;
        width: 96px;
    }
}
