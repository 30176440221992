@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../../common/styles/abstracts/variables-pcg";
@import "../../../common/styles/abstracts/mixins";

.document-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.document-content {
    @include mq("tablet-wide") {
        display: flex;
        flex-direction: row;

        &__text-container {
            width: 67%;
            padding-right: 32px;
        }

        &__side-section {
            width: 33%;
            position: sticky;
            top: 140px;
            align-self: flex-start;
        }
    }

    padding-top: 40px;
    padding-bottom: 40px;

    @include mq("tablet") {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    @include mq("desktop-wide") {
        &__text-container {
            padding-right: 60px;
        }
    }

    @include mq("desktop-wide-helper") {
        &__text-container {
            padding-right: 64px;
        }
    }
    display: flex;
    flex-direction: column-reverse;
}

.int-hero {
    padding-top: 36px;
    padding-bottom: 36px;
    background-color: $clr-gray-100;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include mq("tablet-wide") {
        padding-top: 56px;
        padding-bottom: 56px;
    }

    &-chips {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }
    &-applicable-for {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        &-meta {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
            row-gap: 0px;
        }
        &-meta div:not(:first-child) {
            color: $clr-gray-500;
        }
        &-meta span:not(:first-child) {
            color: $clr-gray-500;
        }
        &-title {
            h3 {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
        &-text {
            p {
                margin-top: 0px;
            }
        }
    }
}

.units {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap; 
}

.applicable {
    font-weight: 600;
    font-size: 16px;
    font-family: "Sora";
    line-height: 20px;
}

.selected-item {
    border-left: 2px solid $clr-secondary-700;
}
