@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/_variables-pcg";

.modal {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq("phablet", min) {
        padding-top: unset;
    }

    &__dialog {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &--success,
        &--error {
            width: 100%;
            height: 100%;
            overflow: auto;
        }
    }

    &__content {
        background-color: $clr-white;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &--success,
        &--error {
            background-color: $clr-white;
            padding: 40px 20px;
        }

        @include mq("phablet") {
            width: 70%;
            height: 95%;
        }
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 16px;

        &__title {
            margin: 0;
            flex: 1;
            display: flex;
            align-items: center;

            h2 {
                font-size: 26px;
                line-height: 32px;

                @include mq("tablet", min) {
                    font-size: 28px;
                }
            }
        }
    }

    &__action-buttons {
        display: flex;
        justify-content: flex-end;
    }
}
.message__header__button {
    width: 24px;
    height: 24px;
}

.filter {
    width: 100%;
}
