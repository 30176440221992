@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";

.custom-datatable {
    padding: 0 5vw;
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;

    &.scrollable {
        overflow-x: auto;
        overflow-y: auto;
    }

    &.gridlines {
        table,
        th,
        td {
            border: 1px solid $clr-gray-300;
        }
    }

    table th {
        color: $clr-black;
    }

    &.striped tbody tr:nth-child(even) {
        background-color: $clr-gray-100;
    }

    .custom-datatable__table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
        thead {
            background-color: #e6e9ec;

            th {
                padding: 10px;
                text-align: left;
                position: relative;
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                // max-width: 150px;
            }
        }
        tbody {
            tr {
                td {
                    a {
                        padding-right: 5px;
                    }
                    text-align: left;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                }
            }
        }
    }
}
