@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";

.diagram-container {
  @include flex(row, center, center);
  flex: 0;
  position: relative;
  width: 100%;
}

.pan-and-zoom {
  max-width: 800px;
  margin-top: 40px;
  margin-bottom: 40px;
  @include mq("tablet", max) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.diagram-tools {
  @include flex(column, center, center);
  width: 40px;
  flex: 0;
  background: rgb(128, 128, 128, 0.5);
  position: absolute;
  right: 0;
  top: 0;

  button {
    background: none;
    border: none;
    margin-left: 0;
    opacity: 1;
    cursor: pointer;

    img {
      width: 32px;
      margin: 0;
      opacity: 1;
    }
  }
}

.diagram {
  @include flex(row, center, center);
  width: 100%;
  height: 500px;

  @include mq("phone", max) {
    height: 300px;
  }
}

.image-description {
  background-color: $clr-gray-100;

  @include flex(column, space-between, center);

  p {
    margin: 0;
  }

  &__title {
    font-style: italic;
    font-weight: 600;
    margin-top: 5px;
  }

  &__description {
    font-style: italic;
  }
}
