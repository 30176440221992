//#region FLEX
@mixin flex($direction: row, $justify: center, $align: center, $flex-wrap: nowrap) {
    display: flex;
    flex-wrap: $flex-wrap;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

@mixin btn-arrow($fill: $clr-btn-arrow, $display: flex, $margin-left: 8px, $position: unset) {
    .btn-arrow svg {
        display: $display;
        margin-left: $margin-left;
        position: $position;

        path {
            fill: $fill;
        }
    }
}

//#endregion FLEX

//#region MEDIA_QUERIES
$breakpoints: (
    "phone-small": 320px,
    "phone": 415px,
    "phone-wide": 480px,
    "tablet-small": 640px,
    "phablet": 680px,
    "tablet": 768px,
    "tablet-edge": 769px,
    "tablet-wide": 1024px,
    "desktop": 1248px,
    "desktop-wide": 1440px,
    "desktop-wide-helper": 1640px,
    "tv": 1820px,
    "tv-wide": 2400px,
);

@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);

        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

//#endregion MEDIA_QUERIES

//#region TITLE_DECORATION

@mixin title-decoration($color: $clr-tertiary-500, $width: 50%, $height: 24px, $bottom: 8px) {
    position: relative;
    transform-style: preserve-3d;

    &::before {
        content: "";
        position: absolute;
        background: $color;
        left: 0;
        bottom: $bottom;
        width: $width;
        height: $height;
        transform-origin: left;
        animation: animateTitle 0.75s 1;
        animation-fill-mode: forwards;
        z-index: -1; // safari fix
    }
}

@keyframes animateTitle {
    from {
        transform: translateZ(-1px) scaleX(0);
    }

    to {
        transform: translateZ(-1px) scaleX(1);
    }
}

//#endregion TITLE_DECORATION

//#region BACKGROUND
@mixin background($imgpath, $size: cover 0, $position: center) {
    background: {
        image: url($imgpath);
        size: $size;
        repeat: no-repeat;
        position: $position;
    }
}

//#endregion BACKGROUND

//#region TEXT_LIMIT
@mixin text_limit($lines: 8) {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

//#endregion TEXT_LIMIT

@mixin warning_message {
    display: flex;
    color: $clr-tertiary-500;
    font-size: 10px;
    margin: 4px 4px 0 0;
    justify-content: flex-end;
}

//#region DOT_DECORATION
$dot-bottom-offsets-map: (
    55px: 12.5px,
    50px: 13px,
    48px: 9.5px,
    44px: 12px,
    42px: 11px,
    36px: 9.5px,
    32px: 8.5px,
    29px: 8px,
    28px: 8px,
    26px: 7px,
    24px: 7.6px,
    23px: 6px,
    20px: 5px,
    16px: 4.5px,
);

@mixin dot_decoration($font-size, $dot-bottom-offset: 0px, $use-offset: false) {
    position: relative;

    &::after {
        background: var(--title-decoration);
        content: "";
        position: absolute;
        margin-left: calc(#{$font-size} / 5);
        height: calc(#{$font-size} / 3);
        width: calc(#{$font-size} / 3);

        @if map_has_key($dot-bottom-offsets-map, $font-size) and not $use-offset {
            bottom: map_get($dot-bottom-offsets-map, $font-size);
        } @else {
            bottom: $dot-bottom-offset;
        }
    }
}

//#endregion DOT_DECORATION

@mixin vertical-spacing($property, $value) {
    @if $property == margin {
        margin-top: $value;
        margin-bottom: $value;
    } @else if $property == padding {
        padding-top: $value;
        padding-bottom: $value;
    }
}

// Use this mixin to bold text on hover instead of just using font-weight in order to prevent layout shifts
// MUST use custom attribute data-text on element to be able to fetch the content
@mixin hover-bold($font-weight, $font-color) {
    position: relative;

    &::after {
        @content;
        content: attr(data-text);
        font-weight: normal;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
    }

    &:hover {
        color: transparent;
    }

    &:hover::after {
        font-weight: $font-weight;
        opacity: 1;
        color: $font-color;
        justify-content: start;
    }
}
