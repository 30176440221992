@import "src/common/styles/abstracts/variables-pcg.scss";
.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

}

.login-title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.button.is-link{
    border: 1px solid #FFEA24;
    color: #FFEA24;
    margin: auto;
    flex-grow: 1;
}

.login-btn-container {
    width: 100px;
    height: 100px;
}