a {
  &:visited {
    color: initial;
  }
}

.title {
  &__large {
    $font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;
    font-size: $font-size;
    @include dot_decoration($font-size);

    &::before {
      top: 22px;
    }

    @include mq("tablet", min) {
      $font-size: 48px;
      font-size: $font-size;
      line-height: 54px;
      @include dot_decoration($font-size);

      &::before {
        top: unset;
      }
    }

    @include mq("desktop", min) {
      $font-size: 55px;
      font-size: $font-size;
      line-height: 64px;
      @include dot_decoration($font-size);
    }
  }

  &__white {
    --title-decoration: #ade1f8; // TODO: check color
    text-transform: uppercase;
    $font-size: 32px;
    font-size: $font-size;
    line-height: 40px;
    color: $clr-white;
    margin: 24px 0;
    @include dot_decoration($font-size);

    &::before {
      top: 22px;
    }

    @include mq("tablet", min) {
      $font-size: 48px;
      font-size: $font-size;
      line-height: 54px;
      @include dot_decoration($font-size);

      &::before {
        top: unset;
      }
    }

    @include mq("desktop", min) {
      $font-size: 55px;
      font-size: $font-size;
      line-height: 64px;
      @include dot_decoration($font-size);
    }
  }

  &__homepage {
    @include flex(column, flex-start, flex-start);
    --title-decoration: #{$clr-tertiary-500};
    $font-size: 36px;
    font-size: $font-size;
    text-transform: uppercase;
    margin-top: 24px;
    span:last-child {
      @include dot_decoration($font-size);
    }

    @include mq("tablet", min) {
      $font-size: 48px;
      font-size: $font-size;
      span:last-child {
        @include dot_decoration($font-size);
      }
    }

    @include mq("desktop", min) {
      $font-size: 55px;
      font-size: $font-size;
      span:last-child {
        line-height: 63px;
        @include dot_decoration($font-size);
      }
    }

    &--lang {
      --title-decoration: #{$clr-tertiary-500};
      text-transform: uppercase;
      $font-size: 32px;
      font-size: $font-size;
      line-height: 39px;
      margin-top: 24px;
      max-width: 80%;
      display: flex;
      flex-wrap: wrap;

      span:last-child {
        @include dot_decoration($font-size);
      }

      span {
        margin-right: 12px;
      }

      &::before {
        top: 22px;
      }

      @include mq("tablet", min) {
        $font-size: 48px;
        font-size: $font-size;
        line-height: 51px;
        span:last-child {
          @include dot_decoration($font-size);
        }

        &::before {
          top: unset;
        }
      }

      @include mq("desktop", min) {
        $font-size: 55px;
        font-size: $font-size;
        span:last-child {
          line-height: 62px;
          @include dot_decoration($font-size);
        }
      }
    }
  }
}
