@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";

.hero {
    padding-top: 56px;
    padding-bottom: 56px;
    background-color: $clr-gray-100;
    display: flex;
    flex-direction: column;
    gap: 32px;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-top: 0;
        padding-bottom: 0;
    }

    &__title {
        margin: 0;
    }
}
