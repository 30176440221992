@import "src/common/styles/abstracts/variables-pcg.scss";
.version {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    &-data {
        width: 100%;
        &-wrapper {
            display: flex;
            flex-direction: column;
            gap: 12px;

            h6 {
                margin-bottom: 0px;
            }
            &-description {
                display: flex;
                flex-direction: column;
                gap: 4px;
                padding-bottom: 26px;

                &-text {
                    word-wrap: break-word;
                }

                &-author {
                    font-weight: 700;
                    font-size: 16px;
                    span {
                        font-weight: 400;
                    }
                }
            }
        }
    }
    &-number {
        width: 30px;
    }
    &-number-line-wrapper {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
}

.version:first-of-type .line {
    margin-top: 14px;
}

.version:nth-last-of-type(2) ~ .version:last-of-type .line {
    height: 15px;
}
.version:only-of-type .line {
    height: 0;
}

.versions .version:not(:first-child) .line .circle {
    transform: translate(-56.54%, 100%);
    background-color: white;
    border: 1px solid $clr-primary-300;
}

.line {
    border-left: 1px solid $clr-primary-300;
    position: relative;
}

.circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    background-color: $clr-primary-300;
    transform: translate(-56.54%, -50%);
    border-radius: 50%;
}
