@import "../abstracts/mixins";

// Values for dot font size are taken from "_typography.scss"
$default-dot-color: $clr-tertiary-500;

$dot-desktop: h1 42px, h2 32px, h3 28px, h4 24px, h5 20px, h6 16px;

$dot-mobile: h1 36px, h2 29px, h3 26px, h4 23px, h5 20px, h6 16px;

@each $selector, $font-size in $dot-desktop {
  #{$selector}.dot-decoration {
    --title-decoration: #{$default-dot-color};
    @include dot_decoration($font-size);
  }
}

h1.dot-decoration.display {
  --title-decoration: #{$default-dot-color};
  @include dot_decoration(55px);
}

@include mq("tablet", max) {
  @each $selector, $font-size in $dot-mobile {
    #{$selector}.dot-decoration {
      --title-decoration: #{$default-dot-color};
      @include dot_decoration($font-size);
    }
  }

  h1.dot-decoration.display {
    --title-decoration: #{$default-dot-color};
    @include dot_decoration(42px);
  }
}
