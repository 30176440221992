@import "src/common/styles/abstracts/variables-pcg.scss";
.dashboard-container {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: center;
    height: 70vh;
    // background-color: #f5f5f5;

    .dashboard-item {
        border: 1px solid #f5f5f5;
        border-radius: 16px;
        padding: 16px;
        min-width: 120px;
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 2px;
        cursor: pointer;
        .dashboard-item-icon {
            font-size: 42px;
        }
    }
}