@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";

.modal-search__main {
    @include flex(row, center, flex-end);
    border-bottom: 1px solid black;
    height: auto;
    padding-top: 12px;

    .input {
        -webkit-appearance: none;
        background-color: unset;
        border: none;
        width: 100%;

        &:focus,
        &:focus-visible {
            outline: none;
        }
    }

    .searchIcon-modal {
        height: 24px;
        width: 24px;
        margin-bottom: 7px;
    }

    .searchfield-modal {
        -webkit-appearance: none;
        background-color: unset;
        border: none;
        flex: 1;
        padding: 7px;

        &:focus,
        &:focus-visible {
            outline: none;
        }
    }
}

input,
select,
textarea {
    color: black;
}

#search::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    transform: scale(0.5);
    cursor: pointer;
    background: url(../../assets/img/icons/close.svg);
    background-repeat: no-repeat;
}

#search::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: gray;
}
