@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";

.rich-text {
    max-width: 750px;

    &-insight {
        max-width: 750px;

        @include mq("tv", min) {
            max-width: 900px;
        }
    }

    h1:first-child,
    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child,
    h6:first-child,
    p:first-child {
        margin-top: 0;
        padding-top: 0;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
    }

    ul li {
        @include flex(row, flex-start, flex-start);
        position: relative;
        padding-left: 1rem;

        .square-bullet {
            height: 14px;
            width: 14px;
            background-color: $clr-tertiary-500;
            flex-shrink: 0;
            margin: 5px;
            margin-right: 15px;
        }
    }

    ol.nested {
        margin-top: 0;
    }

    .custom-quote {
        @include flex(column, center, center);
        text-align: left;
        font-family: "Manrope", sans-serif;
        font-style: italic;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 12px;
        padding-bottom: 28px;
        margin-top: 16px;
        margin-bottom: 16px;
        border: 1px solid var(--Secondary-Grey-300, #dce0e5);
        gap: 8px;

        &__decorations-wrapper {
            @include flex(row, center, center);
            width: 100%;
            gap: 8px;
            &__icon {
                width: 32px;
                height: 32px;

                @include mq("tablet", min) {
                    width: 48px;
                    height: 48px;
                }
            }
            &__top-line {
                border: 1px solid rgb(255, 234, 36);
                width: 100%;
            }

            @include mq("tablet", min) {
                gap: 24px;
            }
        }

        &__bottom-line {
            border: 1px solid rgb(255, 234, 36);
            margin-top: 16px;
            width: 100%;
        }

        &__content {
            align-self: stretch;
            font-family: "Manrope", sans-serif;
            font-size: 16px;
            font-style: italic;
            line-height: 150%;

            @include mq("tablet", min) {
                font-size: 20px;
            }
        }

        &__author-wrapper {
            @include flex(row, flex-start, center);
            align-self: stretch;
            padding-top: 8px;
            gap: 12px;

            &__img {
                width: 44px;
                height: 44px;
                border-radius: 50%;
                object-fit: cover;
            }
            &__content-wrapper {
                @include flex(column, flex-start, flex-start);

                &__author {
                    font-family: "Sora", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    line-height: 18px;
                    font-weight: 600;
                    margin-bottom: 4px;
                    margin-top: 0;

                    @include mq("tablet", min) {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                &__author-details {
                    @include flex(row, center, baseline);
                    &__item {
                        font-family: "Sora", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        line-height: 18px;
                        margin: 0;

                        &.bolded {
                            font-weight: 600;
                        }
                        @include mq("tablet", min) {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                }
            }

            @include mq("tablet", min) {
                padding-top: 16px;
            }
        }

        @include mq("tablet", min) {
            padding-left: 40px;
            padding-right: 40px;
            margin-top: 32px;
            margin-bottom: 32px;
            padding-bottom: 36px;
        }
    }

    a {
        color: $clr-link;
        overflow-wrap: anywhere;
        text-decoration: underline;
    }

    figure {
        margin: 0;
    }

    img {
        width: 100%;
        margin: 0 0;
        max-width: 800px;
    }

    .icon {
        &--external-link {
            width: 20px;
            margin: 0 0 -4px 0;
        }
    }

    .code-snippet {
        margin: 32px 0px;

        &__header {
            @include flex(row, space-between);
            background-color: $clr-gray-100;
            border-bottom: 1px solid $clr-gray-300;
            padding: 8px 16px;
        }

        &__language {
            font-family: Sora;
            font-size: 12px;
            letter-spacing: 1.2px;
            text-transform: uppercase;
        }

        &__copy-button {
            @include flex();
            width: 32px;
            height: 32px;
            cursor: pointer;
        }

        &__copy-icon {
            width: 16px;
            height: 16px;
            margin: 8px;
        }

        &__button-wrapper {
            @include flex();
            gap: 8px;
        }

        &__tooltip {
            color: $clr-black;
            font-family: Manrope;
            font-size: 12px;
            line-height: 16px;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0.3s;

            &--visible {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    br {
        content: "";
        display: block;
    }

    h1 {
        font-size: 42px;
        line-height: 52px;
        margin-top: 40px;
        margin-bottom: 24px;
        font-weight: 600;
    }

    h1 > a {
        font-size: 42px;
    }

    h2 {
        font-size: 32px;
        line-height: 40px;
        margin-top: 48px;
        margin-bottom: 16px;
        font-weight: 600;
    }

    h2 > a {
        font-size: 32px;
    }

    h3 {
        font-size: 28px;
        line-height: 36px;
        margin-top: 40px;
        margin-bottom: 16px;
        font-weight: 600;
    }

    h3 > a {
        font-size: 28px;
    }

    h4 {
        font-size: 24px;
        line-height: 32px;
        margin-top: 40px;
        margin-bottom: 16px;
        font-weight: 600;
    }

    h4 > a {
        font-size: 24px;
    }

    h5 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        margin-top: 40px;
        margin-bottom: 16px;
    }

    h5 > a {
        font-size: 20px;
    }

    h6 {
        text-transform: uppercase;
        color: $clr-tertiary-500;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        margin-top: 40px;
        margin-bottom: 8px;
        letter-spacing: 0.0015em;
    }

    h6 > a {
        font-size: 16px;
    }

    h1:lang(el),
    h2:lang(el),
    h3:lang(el),
    h4:lang(el),
    h5:lang(el) {
        font-weight: 700;
    }

    iframe {
        margin-bottom: 40px;
    }

    p {
        font-size: 16px;
    }

    p > a {
        font-size: 16px;
    }

    a {
        margin: 0;
    }

    ol {
        margin-bottom: 0;
        margin-top: 20px;
    }

    p:empty {
        display: none;
    }

    @include mq("tablet", max) {
        h1 {
            font-size: 36px;
            line-height: 44px;
        }

        h1 > a {
            font-size: 36px;
        }

        h2 {
            font-size: 29px;
            line-height: 36px;
        }

        h2 > a {
            font-size: 29px;
        }

        h3 {
            font-size: 26px;
            line-height: 32px;
        }

        h3 > a {
            font-size: 26px;
        }

        h4 {
            font-size: 23px;
            line-height: 28px;
        }

        h4 > a {
            font-size: 23px;
        }

        h5 {
            font-size: 20px;
            line-height: 24px;
        }

        h5 > a {
            font-size: 20px;
        }

        p {
            font-size: 16px;
            line-height: 1.8;
            margin: 0;
        }
    }

    .player-wrapper {
        position: relative;
        padding-top: 56.25%;

        margin-bottom: 12px;

        iframe {
            @include mq("tablet", max) {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }

    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }
    .rich-text-small-cta {
        @include flex();
        margin: 32px 0px;

        &.center {
            justify-content: center;
        }

        &.right {
            justify-content: flex-end;
        }

        &.left {
            justify-content: flex-start;
        }
    }
}

.big,
.medium,
.small {
    @include flex(row, space-between, center);
    width: 100%;
    margin-top: 36px;
    margin-bottom: 36px;
    @include mq("tablet", max) {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}

.big {
    .rich-text-image-wrapper {
        width: fit-content;
    }

    .image-description {
        h4,
        p {
            margin: 0;
            text-align: center;
            line-height: 1.5;
        }

        p {
            font-size: 14px;
        }
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: flex-end;
    }

    &.left {
        justify-content: flex-start;
    }
}

.medium {
    .rich-text-image-wrapper {
        width: 100%;

        @include mq("phablet", min) {
            width: 66%;
        }

        p {
            font-size: 14px;
        }
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: flex-end;
    }

    &.left {
        justify-content: flex-start;
    }
}

.small {
    .rich-text-image-wrapper {
        max-width: 50%;
        min-width: 72px;

        @include mq("phablet", min) {
            max-width: 33%;
        }

        p {
            font-size: 14px;
        }
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: flex-end;
    }

    &.left {
        justify-content: flex-start;
    }
}

.inline {
    flex-wrap: wrap;

    .rich-text-image-wrapper {
        p {
            font-size: 14px;
        }
    }

    @include mq("tablet", max) {
        &.small {
            .rich-text-image-wrapper {
                margin-bottom: 16px;
            }
        }
    }

    @include mq("tablet", min) {
        display: block;
        margin-top: 16px;
        margin-bottom: 16px;

        &.small {
            &.right {
                .rich-text-image-wrapper {
                    float: right;
                    margin-left: 16px;
                }

                .rich-text-to-wrap-around {
                    text-align: justify;
                }
            }

            &.left {
                .rich-text-image-wrapper {
                    float: left;
                    margin-right: 16px;
                }
            }
        }
    }
}

.image-description {
    background-color: $clr-gray-100;
    padding: 12px;
    margin-top: 0;

    &__title {
        font-style: italic;
        font-weight: 600;
    }

    &__description {
        font-style: italic;
    }
}

iframe {
    width: 100% !important;
    @include mq("tablet", max) {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}

.image-responsive {
    &--desktop {
        @include mq("phablet", min) {
            display: inline;
        }
        display: none;
    }

    &--mobile {
        @include mq("phablet", min) {
            display: none;
        }
    }
}
blockquote {
    margin: 16px 40px;
}
