@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../../common/styles/abstracts/variables-pcg";
@import "../../../common/styles/abstracts/mixins";

.contents {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        font-family: Sora;
    }

    &-body {
        cursor: pointer;
        &-item {
            border-left: 2px solid $clr-gray-300;
            padding-top: 8px;
            padding-bottom: 8px;

            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.15%;
                color: $clr-gray-500;
            }

            &-active {
                border-left: 2px solid $clr-secondary-700;
                padding-top: 8px;
                padding-bottom: 8px;

                span {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.15%;
                    color: $clr-black;
                }
            }
        }
    }
}

.table-of-contents {
  background-color: $clr-gray-100;
  margin-bottom: 40px;
  padding-left: 8px;

  &.expanded {
    padding-bottom: 8px;
  }

  &__header {
    @include flex(row, flex-start, center);
    height: 48px;

    &__arrow {
      margin-left: auto;
      margin-right: 12px;
    }

    &__title {
      padding-left: 8px;
    }

    * {
      @include vertical-spacing(margin, 0);
    }
  }

  @include mq("desktop", min) {
    width: 50%;
  }

  @include mq("tablet-wide", min) {
    background-color: $clr-white;
    width: 208px;
    padding: 0;

    &__header {
      @include flex(row, flex-start, flex-start);

      &__title {
        padding-left: 0;
      }
    }

    &__item {
      max-width: unset;

      &--active {
        max-width: unset;
      }
    }
  }
}

