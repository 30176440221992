@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";

.scroll-to-top-button {
  z-index: 999;
  padding: 16px;
  position: fixed;
  bottom: 5%;
  right: 5%;
  height: 48px;
  width: 48px;
  background-color: rgba(255, 234, 36, 0.7);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.4s linear, opacity 0.4s linear;

  @include mq("desktop-wide", min) {
    width: 56px;
    height: 56px;
    background-color: $clr-tertiary-500;
  }
  @include mq("tv", min) {
    bottom: 10%;
    right: 10%;
  }
  @include mq("tv-wide", min) {
    bottom: 10%;
    right: 15%;
  }
}
.fadeOut {
  visibility: visible;
  opacity: 1;
}
.arrow {
  width: 16px;
  height: 16px;

  @include mq("desktop-wide", min) {
    width: 24px;
    height: 24px;
  }
}
