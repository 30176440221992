@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../styles/abstracts/mixins";

.flags-row{
  @include flex(row,start,start)
}

img.flag {
  height: 11px;
  width: 16px;
}

.flag-a{
  padding-right: 12px;
}

.flag {
  background: url(https://primefaces.org/cdn/primereact/images/flag/flags_responsive.png)
    no-repeat;
  background-size: 100%;
  vertical-align: middle;
  padding-right: 2px;
  margin-bottom: 4px;
}

.flag-ch {
  background-position: 0 22.31405%
}

.flag-de {
  background-position: 0 22.31405%;
}

.flag-gr {
  background-position: 0 34.710744%;
}

.flag-se {
  background-position: 0 77.68595%;
}

.flag-en {
  background-position: 0 92.561983%;
}
