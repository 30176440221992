@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../common/styles/abstracts/mixins";

:root {
    --title-decoration: transparent;
}

.s404 {
    @include flex(column, center, center);
    background-color: $clr-primary-500;
    gap: 40px;

    @include mq("tablet-small") {
        @include flex(row-reverse, center, center);
        gap: 60px;
    }

    @include mq("tablet") {
        gap: 80px;
    }

    @include mq("desktop") {
        gap: 140px;
    }

    &__container {
        background-color: #1f274b;
        min-height: inherit;
        @include flex(column, center, center);
    }

    &__content-wrapper {
        @include flex(column, center, flex-start);
        gap: 32px;

        @include mq("tablet-small") {
            max-width: 50%;
        }

        @include mq("tablet-wide") {
            gap: 48px;
            max-width: 58%;
        }

        &__main {
            @include flex(column, left, left);
            gap: 16px;
            &__header {
                color: $clr-tertiary-500;
                margin: 0px;
                font-size: 36px;

                @include mq("tablet") {
                    font-size: 42px;
                }
            }

            &__text {
                color: $clr-white;
                font-size: 20px;
                line-height: 24px;
                font-weight: 400;

                @include mq("tablet-wide") {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }

        &__links {
            @include flex(column, flex-start, flex-start);
            font-family: "Sora", sans-serif;
            font-size: 16px;
            line-height: 24px;
            gap: 16px;

            @include mq("tablet-wide") {
                font-size: 20px;
                line-height: 32px;
                gap: 24px;
            }

            &__home {
                font-family: "Manrope", sans-serif;
                color: $clr-white;

                &__link {
                    font-family: "Sora", sans-serif;
                    color: $clr-tertiary-500;
                    text-decoration: underline;
                    font-weight: 600;
                    &:visited {
                        color: $clr-tertiary-500;
                    }
                }
            }

            &__insights {
                @include flex(row, flex-start, center);
                @include btn-arrow($clr-tertiary-500);
                color: $clr-tertiary-500;
                text-decoration: underline;
                font-weight: 600;
                &:visited {
                    color: $clr-tertiary-500;
                }
            }

            &__services {
                @include flex(row, flex-start, center);
                @include btn-arrow($clr-tertiary-500);
                color: $clr-tertiary-500;
                text-decoration: underline;
                font-weight: 600;
                &:visited {
                    color: $clr-tertiary-500;
                }
            }

            &__about {
                @include flex(row, flex-start, center);
                @include btn-arrow($clr-tertiary-500);
                color: $clr-tertiary-500;
                text-decoration: underline;
                font-weight: 600;
                &:visited {
                    color: $clr-tertiary-500;
                }
            }
        }
    }

    &__image-wrapper {
        @include flex(column, center, center);

        &__image {
            width: 220px;

            @include mq("tablet-small") {
                width: 180px;
            }
            @include mq("tablet") {
                width: 220px;
            }
            @include mq("tablet-wide") {
                width: 240px;
            }
            @include mq("desktop") {
                width: 260px;
            }
        }
    }
}
