@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";

.call-to-action {
  @include flex(column, space-between, center);

  background-color: $clr-gray-100;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 32px;
  gap: 24px;

  &__wrapper {
    @include flex(column);
    gap: 16px;
  }
  > * {
    text-align: center;
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 24px;
  }

  button {
    width: 100%;
    margin-top: 24px;

    @include mq("phone-wide", min) {
      width: auto;
    }
  }

  &__title {
    $font-size: 28px;
    font-size: $font-size;
    width: 100%;
    text-align: center;
    @include dot_decoration($font-size);
  }

  &__description {
    .rich-text {
      max-width: unset;
    }
  }

  @include mq("tablet", min) {
    margin-top: 40px;
    margin-bottom: 40px;
    h3 {
      font-size: 28px;
      line-height: 36px;
    }
    p {
      line-height: 24px;
    }
  }
}
