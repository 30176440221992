@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";

.insights-filters {
    &__wrapper {
        @include flex(row, space-between, center);
        padding-top: 40px;
        padding-bottom: 0;
    }

    &__clear-filters {
        @include flex(row, flex-end, center);
        align-self: center;
        flex: 1;
        line-height: 24px;

        @include mq("tablet-wide", min) {
            line-height: 28px;
        }
        button {
            font-family: "Manrope", sans-serif;
            color: $clr-primary-500;
        }

        @include mq("phablet", min) {
            flex: 3;
        }
    }

    &__left {
        @include flex(row, space-between, flex-start);
        flex: 3;

        &-flex-1 {
            @include flex(row, space-between, flex-start);
            flex: 1;
        }
    }
}

.add_filters,
.add_filters_underline {
    font-family: "Manrope", sans-serif;
    text-transform: capitalize;
    font-weight: 300;
    font-size: 14px;
    background-color: unset;
    border: none;
    cursor: pointer;
    flex: 1;
    padding-left: 0;
    color: $clr-black;
    white-space: nowrap;

    @include mq("tablet-wide", min) {
        font-family: "Sora", sans-serif;
        font-size: 20px;
        text-transform: uppercase;
    }

    &--counter {
        font-weight: 400 !important;
        color: $clr-link;
        margin-left: 5px;
        font-size: 14px;
        line-height: 24px;
    }

    &--tags,
    &--services,
    &--type {
        @include flex(row, flex-start, flex-start);
        font-weight: 700;
        text-transform: uppercase;
        font-size: 20px;
        background-color: unset;
        border: none;
        cursor: pointer;

        &--counter {
            color: $clr-link;
            margin-left: 5px;
            font-weight: 400;
        }
    }
}

.increase_padding_right {
    padding-right: 12px;
}

.add_filters:lang(el),
.add_filters_underline:lang(el) {
    font-weight: 700;
}

.add_filters {
    position: relative;
    line-height: 24px;
    min-width: 128px;
    min-height: 24px;
    @include flex(row, flex-start, flex-start);

    @include mq("tablet-wide", min) {
        line-height: 24px;
        font-size: 16px;
        font-weight: 600;
        min-width: fit-content;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 2px;
        height: 6px;
        background-color: $clr-tertiary-500;
        width: 100%;
        z-index: -1;
        transition: 0.5s;
        transform-origin: left;
        transform: scaleX(0);
    }
}

.add_filters_underline {
    &::before {
        transform: scaleX(0.5);
    }
}

.multi-filters {
    position: relative;
    line-height: 24px;
    border-bottom-style: solid;
    border-color: $clr-gray-300;

    @include mq("tablet-wide", min) {
        line-height: 28px;
        border-bottom-style: none;
    }
}

.unselectable {
    user-select: none;
}

.btn__type__filter {
    border: none;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin-right: 8px;
    background-color: $clr-gray-100;
    color: $clr-black;
    margin-bottom: 8px;

    &--all--active,
    &--news--active,
    &--casestudies--active,
    &--articles--active,
    &--pressreleases--active {
        background-color: $clr-secondary-500;
        color: $clr-secondary-500;
        border: none;
        margin-right: 8px;
        padding: 8px 16px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
    }

    @include mq("phablet", min) {
        margin-bottom: 0px;

        &--all--active,
        &--news--active,
        &--casestudies--active,
        &--articles--active,
        &--pressreleases--active {
            margin-bottom: 8px;
        }
    }

    &--news--active {
        background-color: $clr-secondary-500;
        color: $clr-on-secondary-500;
    }

    &--casestudies--active {
        background-color: $clr-tertiary-500;
        color: $clr-on-tertiary-500;
    }

    &--articles--active {
        background-color: $clr-primary-500;
        color: $clr-on-primary-500;
    }

    &--pressreleases--active {
        background-color: $clr-quaternary-500;
        color: $clr-on-quaternary-500;
    }
}

.multi_filter_dropdown {
    margin-top: 12px;

    &__sticky {
        position: sticky;
        top: 0;
        background-color: $clr-white;
        z-index: 1001;
    }

    p {
        margin-bottom: 12px;
    }

    &__title {
        @include flex(row, space-between, center);
    }

    &__searchBar {
        @include flex(row, flex-start);
        background: $clr-gray-100;
        border-radius: 4px;
        min-height: 50px;
        margin-bottom: 12px;

        .searchIcon {
            cursor: pointer;
            height: 24px;
            width: 24px;
            margin-left: 10px;
            margin-right: 2px;
        }

        input {
            width: 100%;
            border-radius: 4px;
            border: none;
            border-bottom: 0;
            min-height: 50px;
            padding: 12px;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: $clr-gray-500;
            }
        }

        input[type="text"] {
            background-color: $clr-gray-100;
        }
    }

    &__clear-filter {
        @include flex(row, space-between, flex-start);
        margin-top: 28px;

        button {
            font-family: "Manrope", sans-serif;
            font-size: 16px;
            color: $clr-primary-500;
            background-color: $clr-white;
            border: none;
            cursor: pointer;
            margin-right: 0;
        }

        @include mq("phablet", min) {
            margin-top: 8px;
        }
    }
    &__reset-div {
        @include flex(row, space-between, center);
        padding: 40px 0 6px 0;
        @include mq("tablet-wide", min) {
            padding: 20px 0 2px 0;
        }
    }

    &__filtersList {
        @include flex(column, flex-start, flex-start);
        padding-bottom: 0;
        row-gap: 4px;
        line-height: 24px;

        &::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }
    }

    &--no-results {
        width: 100%;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;

        p {
            margin: 0;
        }
    }

    &__hide {
        display: none;
    }

    &__show {
        @include flex(column, flex-start, stretch);

        background: $clr-white;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 999;
        width: 440px;
        position: absolute;
        min-width: 230px;
        border: 20px solid $clr-white;
        border-bottom: 0;
        overflow: scroll;
        margin-top: 12px;
        &::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }

        @include mq("phablet", min) {
            max-height: 408px;
        }
    }
}
.number {
    display: inline-block;
    min-width: 8px;
    @include mq("tablet-wide", min) {
        min-width: 12px;
    }
}
.modal-options {
    padding-bottom: 50px;
}
.btn__filter__insights {
    margin: 0;
    font-size: 16px;
}
.reset {
    font-weight: 400;
}
.no-options {
    padding-top: 10px;
    padding-bottom: 10px;
}
