@import "../abstracts/mixins";

body,
button {
  font-family: "Manrope", sans-serif;
}

button {
  font-family: "Sora", sans-serif;
  cursor: pointer;
}

.display {
  font-family: "Sora", sans-serif;
  font-size: 55px;
  line-height: 64px;
  font-weight: 700;
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Sora", sans-serif;
  font-weight: 700;
  margin-top: 4px;
}

h1,
h2,
h3,
h4 {
  margin-bottom: 12px;
}

h5,
h6 {
  margin-bottom: 8px;
}

h1 {
  font-size: 36px;
  line-height: 44px;
}

h2 {
  font-size: 29px;
  line-height: 36px;
}

h3 {
  font-size: 26px;
  line-height: 32px;
}

h4 {
  font-size: 23px;
  line-height: 28px;
}

h5 {
  font-size: 20px;
  line-height: 24px;
}

h6 {
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
}

h1:lang(el),
h2:lang(el),
h3:lang(el),
h4:lang(el),
h5:lang(el),
h6:lang(el) {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
}

@include mq("tablet", min) {
  .display {
    font-size: 42px;
    line-height: 52px;
  }

  h1 {
    font-size: 42px;
    line-height: 52px;
  }

  h2 {
    font-size: 32px;
    line-height: 40px;
  }

  h3 {
    font-size: 28px;
    line-height: 36px;
  }

  h4 {
    font-size: 24px;
    line-height: 32px;
  }
}

p,
input,
textarea,
li {
  font-size: 16px;
  line-height: 1.5;
  font-family: "Manrope", sans-serif;
}

p {
  margin-top: 4px;

  &:empty {
    min-height: 12px;
  }
}
