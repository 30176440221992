@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";

.section__image-text {
    @include flex(column, center, center);

    background-clip: content-box;
    background-color: $clr-gray-100;
    padding-top: 40px;

    &.case-studies {
        padding-left: 5vw;
        padding-right: 5vw;
    }

    h3 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px;
    }

    img {
        padding-top: 40px;
        max-width: 100%;
    }

    aside {
        text-align: center;
        padding: 40px 24px;
    }

    @include mq("phablet", min) {
        @include flex(column, center, center);
        div {
            line-height: 150%;
        }
        aside {
            padding: 40px 60px;
        }
    }

    @include mq("tablet", min) {
        @include flex(row, normal, center);
        h3 {
            font-size: 24px;
            line-height: 28px;
        }
        img {
            order: 1;
            margin-right: 20px;
            padding: 40px 0 40px 40px;
        }
        aside {
            text-align: left;
            order: 2;
            padding: 40px;
        }
    }

    @include mq("tablet-edge", min) {
    }
    @include mq("tablet-wide", min) {
        img {
            order: 1;
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    @include mq("desktop-wide", min) {
        img {
            padding-left: 60px;
        }
    }
}
