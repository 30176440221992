@keyframes triangles{
  from{
    transform: scale(.8);
  }
  to {
    transform: scale(1);
  }
}

@keyframes logoAnimation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Rotate {
  0%,
  100% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }
}
