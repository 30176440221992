@import "src/common/styles/abstracts/variables-pcg.scss";
@import "bulma";
@import "primereact/resources/themes/lara-light-cyan/theme.css";

:root {
    --bulma-link: #ffea24;
    --bulma-link-invert: #ffffff;
    --bulma-link-invert-l: #ffea24;
    --bulma-body-background-color: #ffffff;
    --bulma-body-color: black;
    --bulma-table-color: red;
}
