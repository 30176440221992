@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/_variables-pcg";

.checkbox-container {
    @include flex(row, flex-start, flex-start);
    padding: 10px 0 10px 0 !important;

    &--checked .checkbox__checkmark {
        background-color: $clr-primary-500;
        border: none;
    }

    &--checked .checkbox__checkmark:after {
        display: block;
    }
}

.checkbox__input {
    &--hideDefault {
        position: absolute;
        opacity: 0;
        pointer-events: none;
    }
}

.checkbox__wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox__item {
    margin-left: 14px;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;

    &--checked {
        margin-left: 16px;
        color: $clr-tertiary-500;
        font-size: 16px;
    }

    a {
        color: green;
        text-decoration: none;
    }
}

.checkbox__checkmark {
    display: block;
    border: 1px solid $clr-black;
    min-height: 16px;
    min-width: 16px;
    background-color: $clr-white;
    cursor: pointer;

    &--big {
        min-height: 20px;
        min-width: 20px;
    }

    &::after {
        content: "";
        position: relative;
        display: none;
        left: 5.5px;
        top: 1.5px;
        width: 5px;
        height: 11px;
        border: solid $clr-tertiary-500;
        border-width: 0 1.5px 1.5px 0;
        transform: rotate(45deg);
    }

    &--big::after {
        left: 7px;
        top: 1.5px;
        width: 5px;
        height: 13px;
        border: solid $clr-tertiary-500;
        border-width: 0 1.5px 1.5px 0;
        transform: rotate(45deg);
    }
}
