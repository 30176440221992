@import "src/common/styles/abstracts/variables-pcg.scss";
@import "core/reset";
@import "core/typography";
@import "core/layouts";
@import "core/elements";
@import "core/animations";
@import "core/dot";
@import "../styles/fonts.scss";

.triangle-first {
  animation: triangles 2s 1;
  transform-origin: right;
}

.triangle-second {
  background: green;
  animation: triangles 1s 1;
  transform-origin: right;
}

.social {
  .social-primary-color,
  .social-secondary-color {
    transition: fill 0.3s ease;
    fill: inherit;
  }

  .social-primary-color {
    fill: $clr-primary-500;
  }

  .social-secondary-color {
    fill: white;
  }

  &:hover {
    .social-primary-color {
      fill: $clr-tertiary-500;
    }

    .social-secondary-color {
      fill: $clr-primary-500;
    }
  }

  &:active {
    .social-primary-color {
      fill: $clr-tertiary-500;
    }

    .social-secondary-color {
      fill: $clr-primary-500;
    }
  }
}
