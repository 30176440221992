@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../../styles/abstracts/mixins";

.mobile-navigation-wrapper {
    @include flex(row, space-between, center);
    height: 60px;
    @include mq("phablet", min) {
        display: none;
    }
    position: sticky;
    z-index: 500;
    top: 0;
    background-color: $clr-white;
    border-bottom: 1px solid $clr-black;
    gap: 40px;
}

.left-items {
    @include flex(row, flex-start, center);
    gap: 24px;
}

.logo-pcg {
    height: 36px;
    width: 54px;
    @include mq("phablet", min) {
        height: 44px;
        width: 65px;
    }
}

.right-items {
    .user {
        display: flex;
        flex-direction: row-reverse;
        &-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        &-image {
            height: 36px;
            width: 36px;
            font-size: 12px;
            line-height: 12px;
            font-family: "Sora", sans-serif;
            font-weight: 600;
            background-color: $clr-tertiary-500;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-data {
            display: none;
            @include mq("tablet-wide", min) {
                @include flex(column, flex-start, flex-start);
                margin-left: 8px;
            }
        }
    }
}

.mobile-nav {
    &__overlay {
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: 100vw;
        overflow: hidden;
        background: rgba($clr-white, 0.5);
        z-index: 9999;

        @include flex(row, flex-start, stretch);
    }

    &__wrapper-pcg {
        @include flex(column, flex-start, flex-start);

        padding: 24px;
        width: 100%;
        height: 100%;
        gap: 24px;
        background: $clr-white;
        box-shadow: 0 24px 14px rgba(0, 0, 0, 0.05);
        max-height: calc(100vh - 60px);
        overflow-y: auto;
        animation: slideIn 0.5s 1;
        padding-top: 24px;
        @include mq("phablet", min) {
            max-height: calc(100vh - 80px);
        }

        @include mq("phablet", min) {
            width: 100%;
        }
        label {
            a {
                text-decoration: none;
                padding-bottom: 0px;
                padding-top: 0px;
                color: $clr-black;
                font-size: 14px;
                line-height: 16px;
            }
        }

        a {
            white-space: unset;
        }

        .mobile-nav__main-pcg {
            font-family: "Sora", sans-serif;
            font-size: 14px;
            max-height: 50px;
            font-weight: 400;
            @include flex(row, flex-start, center);

            animation: slideIn 0.5s 1;

            img {
                width: 12px;
            }
        }

        .mobile-nav__main-pcg:lang(el) {
            font-family: "Manrope", sans-serif;
        }
    }

    &__collapsible {
        transform: scaleY(0);
        transition: 0.25s;
        transform-origin: top;
        max-height: 55px;
        display: none;
        overflow: clip;

        @include mq("tablet-edge", min) {
            transform: scaleY(1);
            max-height: unset;
            opacity: 1;
        }

        .mobile-nav__items {
            padding-top: 24px;
            gap: 24px;

            a {
                font-family: "Sora", sans-serif;
                font-size: 14px;
                font-weight: 400;
                padding-left: 20px;
            }

            a:lang(el) {
                font-family: "Manrope", sans-serif;
            }

            @include flex(column, flex-start, flex-start);
        }
    }

    &--expanded {
        .mobile-nav__collapsible {
            transform: scaleY(1);
            max-height: 100%;
            opacity: 1;
            @include flex(column);
        }
    }
}

.link-logo {
    height: 36px;

    @include mq("phablet", min) {
        height: 44px;
    }
}

.burger-pcg {
    display: flex;
    background: none;
    border: none;
    padding-left: 0;
    margin-left: 0;

    cursor: pointer;
    img {
        margin: 0;
        height: 36px;
    }

    @include mq("tablet-wide", min) {
        display: none;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}
