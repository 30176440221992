@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";

.chips {
    padding: 4px 12px;
    width: fit-content;
    border-radius: 200px;
    font-family: "Sora";
    font-size: 12px;
    line-height: 16px;

    @include mq("tablet-wide") {
        font-size: 14px;
        line-height: 20px;
    }

    overflow: hidden;
    white-space: nowrap;
}
