* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  // object-fit: contain;
  height: auto;
}
