@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../common/styles/abstracts/mixins";

.home-page {
    @include flex(column, center, flex-start);
    gap: 24px;
    padding-top: 40px;
    padding-bottom: 40px;

    @include mq("tablet") {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .text-container {
        @include flex(column, flex-start, flex-start);
    }

    .cards-container {
        display: grid;
        grid-gap: 24px;
        grid-template-columns: 1fr;

        @include mq("phablet") {
            grid-template-columns: repeat(2, 1fr);
        }

        @include mq("desktop") {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
