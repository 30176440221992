@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";

.parent-wrapper {
  top: 0px;
  z-index: 1000;
  position: sticky;
  display: none;
  @include mq("phablet", min) {
    display: block;
  }
  .main-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    height: 60px;
    z-index: 999;
    top: 0;
    align-self: stretch;
    border-bottom: 1px solid $clr-black;
    background-color: $clr-white;

    .navigation-container {
      width: 100%;
    }

    .logo-pcg {
      @include flex(row, flex-start, center);
      width: 65px;
    }
    a:lang(el) {
      font-family: "Manrope", sans-serif;
    }
    .user {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      &-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      &-image {
        height: 36px;
        width: 36px;
        font-family: "Sora", sans-serif;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        @include mq("tablet-wide", min) {
          height: 44px;
          width: 44px;
          font-size: 16px;
          line-height: 16px;
        }
        background-color: $clr-tertiary-500;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-data {
        font-family: "Sora", sans-serif;
        font-weight: 600;
        display: none;
        line-height: 16px;
        font-size: 16px;
        @include mq("tablet-wide", min) {
          @include flex(column, flex-start, flex-start);
          margin-left: 8px;
          gap: 4px;
        }
        &-email {
          font-family: "Manrope", sans-serif;
          font-weight: 400;
          line-height: 12px;
          font-size: 12px;
        }
      }
    }

    .navigation-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 80px;
      .categories {
        @include flex(row, start, stretch);
        flex: 1;
        width: 100%;
        font-family: "Sora", sans-serif;
        height: 100%;
        gap: 16px;
        margin-left: 24px;

        .navbar-item {
          align-content: center;
          padding: 0px;
          font-size: 14px;
        }
      }
    }
  }

  @include mq("phablet", min) {
    .main-wrapper {
      height: 80px;
    }
  }

  @include mq("tablet-wide", min) {
    .main-wrapper {
      .navigation-bar {
        @include flex();

        .categories {
          .btn-nav {
            @include flex(row, flex-start, center);
          }
        }
      }
    }
  }
}
