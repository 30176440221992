@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../../styles/abstracts/mixins";

.service-card {
    @include flex(column, space-between, flex-start);
    text-decoration: none;

    &__content {
        padding-left: 20px;
        margin-bottom: 8px;
        margin-top: 24px;
        position: relative;

        &__title {
            @include flex(row, flex-start, flex-start);
        }

        h5 {
            margin-top: 8px;
            margin-bottom: 12px;
        }

        p {
            @include text_limit(4);
            margin-bottom: 12px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            height: 176px;
            left: 2px;
            width: 1px;
            background: $clr-gray-100;
            z-index: -1;
        }

        @include mq("phablet", max) {
            p {
                @include text_limit(5);
                margin-top: 0;
            }
        }
    }

    &__get-started {
        padding-left: 20px;
        font-family: "Sora", sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: underline;
        color: $clr-black;
    }

    &__slider {
        height: 32px;
        width: 5px;
        left: 0;
        position: absolute;
        transition: 0.5s;
        transform-origin: top;
        top: 45px;
    }

    &:hover {
        .service-card__slider {
            height: 80px;
        }
    }
}
