@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";

.breadcrumbs {
    padding: 8px 0;
    font-size: 14px;
    max-width: 100%;

    ol {
        @include flex(row, flex-start, flex-start, wrap);

        padding-left: 0;
        margin: 0;
        row-gap: 8px;
        gap: 8px;

        li {
            list-style-type: none;
            font-size: 14px;
            overflow-wrap: break-word;
        }

        a {
            color: $clr-black;
            text-decoration: none;
            font-size: 14px;
        }

        a:hover {
            color: $clr-gray-500;
        }

        span {
            margin: 0 5px 0 0;
        }

        a:not(.isInsight):last-child {
            text-decoration: none;
            pointer-events: none;
            cursor: default;
            font-weight: 600;
        }
    }
}
