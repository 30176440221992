@import "src/common/styles/abstracts/variables-pcg.scss";
.tag {
    font-size: 12px;
    text-decoration: none;
    transition: 0.5s;
    background: $clr-card-insight-tag;
    border-radius: 200px;
    width: fit-content;
    padding: 4px 12px;
    font-family: "Sora", sans-serif;
    font-weight: 400;
    position: relative;
    color: $clr-black;
    white-space: nowrap;
    line-height: 16px;

    &-mobile {
        font-size: 14px;
        padding: 8px 16px;
    }

    &-caseStudies {
        background: $clr-tertiary-200;
    }

    &-articles {
        background: $clr-primary-200;
    }

    &-news {
        background: $clr-secondary-100;
    }

    &-pressReleases {
        background: $clr-quaternary-200;
    }

    &-hero {
        margin-top: 24px;
    }

    &.featured {
        background-color: $clr-white;
    }
}

.tag:lang(el) {
    font-family: "Manrope", sans-serif;
}
