@import "src/common/styles/abstracts/variables-pcg.scss";
.btn-container {
    position: fixed;
    top: 7.3vh;
    right: 20vh;
    display: flex;
    gap: 10px;

}
.title {
   padding-left: 25vh;
   padding-top: 15px;
}
.salespeople-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;

    .salesperson-block {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: start;
        
        div {
            width: 25vh;
        }
    }
}

.salesperson-heading {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 20px;
    color: white;
}

