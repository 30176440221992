@import "src/common/styles/abstracts/variables-pcg.scss";
div.scrollable {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    word-break: break-all;
    white-space: pre-line;
}

th {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
}

td {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}

td,
th {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
}
