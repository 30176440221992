/*********************** START COLORS ***********************/
$clr-white: #ffffff;
$clr-black: #000000;

$clr-gray-100: #f5f5f5;
$clr-gray-300: #dce0e5;
$clr-gray-500: #929fae;

$clr-danger: #da5738;
$clr-success: #459952;

// PRIMARY
$clr-primary-200: #c0d0df;
$clr-primary-300: #113d67;
$clr-primary-500: #1f274b;
$clr-primary-700: #090b19;
// PRIMARY TEXT
$clr-on-primary-500: $clr-white;

// SECONDARY
$clr-secondary-100: #e3f3fa;
$clr-secondary-500: #b7e1f2;
$clr-secondary-700: #5ec6f2;
// SECONDARY TEXT
$clr-on-secondary-500: $clr-black;
$clr-on-secondary-700: $clr-black;

// TERTIARY
$clr-tertiary-100: #fcfced;
$clr-tertiary-200: #fdf7c9;
$clr-tertiary-500: #ffea24;
// TERTIARY TEXT
$clr-on-tertiary-500: $clr-black;

// QUATERNARY
$clr-quaternary-200: #b9c4db;
$clr-quaternary-500: #43286f;
$clr-quaternary-700: #161e36;
// QUATERNARY TEXT
$clr-on-quaternary-500: $clr-white;

/*********************** END COLORS ***********************/

/*********************** FUNCTIONAL CSS ***********************/






// APPLIED TO ALL LINKS
$clr-link: $clr-quaternary-500;
// DOT DEFAULT
$title-decoration: $clr-tertiary-500;

// mixins.scss - BUTTON ARROW DEFAULT
$clr-btn-arrow: $clr-black;

/*********************** START FUNCTIONAL CSS TABLES ***********************/

$clr-tbl-col-label: $clr-tertiary-500;
/*********************** END FUNCTIONAL CSS TABLES ***********************/

/*********************** START FUNCTIONAL CSS HEROS ***********************/

// hero-homepage.scss
$clr-hero-homepage-bg: $clr-primary-500;
$clr-hero-homepage-text: $clr-on-primary-500;

// hero-partner.scss
$clr-hero-partner-bg: $clr-secondary-500;

// hero-services.scss
$clr-hero-services-bg: $clr-secondary-500;

// hero-service.scss
$clr-hero-service-bg: $clr-secondary-500;

// hero-insight.scss
$clr-hero-insight-text-articles: $clr-tertiary-500;

/*********************** END FUNCTIONAL CSS HEROS ***********************/

/*********************** START FUNCTIONAL CSS SECTIONS ***********************/

// section-clients.scss
$clr-swiper-pagination: $clr-secondary-500;
$clr-swiper-pagination-active: $clr-primary-500;

// section-offers.scss
$clr-section-offers-bg: $clr-primary-500;
$clr-section-offers-text: $clr-on-primary-500;
$clr-section-offers-link: $clr-tertiary-500;

// section-career-journey.scss
$clr-section-career-journey-link: $clr-tertiary-500;

/*********************** END FUNCTIONAL CSS SECTIONS ***********************/

/*********************** START FUNCTIONAL CSS BUTTONS ***********************/

// button.scss - BUTTON CIRCLE X COLOR
$clr-btn-circle-x: $clr-black;

// button.scss - BUTTON TERNARY
$clr-btn-ternary-text-on-clr-primary-500: $clr-black;
$clr-btn-ternary-text-on-clr-secondary-500: $clr-black;
$clr-btn-ternary-text-on-clr-tertiary-500: $clr-black;
$clr-btn-ternary-text-on-clr-quaternary-500: $clr-black;

// button.scss - OPEN POSITIONS FILTER
$clr-filter-btn-text: $clr-black;
$clr-filter-btn-1: $clr-tertiary-500;
$clr-filter-btn-2: $clr-secondary-500;
$clr-filter-btn-3: $clr-secondary-700;

/*********************** END FUNCTIONAL CSS BUTTONS ***********************/

/*********************** START FUNCTIONAL CSS CARDS ***********************/

// card-offer.scss
$clr-card-offer: $clr-primary-500;
$clr-card-offer-border: transparent;
$clr-card-offer-btn-text: $clr-on-primary-500;
$clr-card-offer-featured: $clr-tertiary-500;
$clr-card-offer-featured-border: transparent;
$clr-card-offer-featured-btn-text: $clr-on-tertiary-500;

// card-job.scss
$clr-card-job-pos-text: $clr-primary-500;
$clr-card-job-border: $clr-secondary-700;

// card-left-line.scss
$clr-card-ll-border-left: $clr-secondary-500;

// card-insight-tags.scss
// insight-page-tags.scss
$clr-card-insight-tag: $clr-gray-100;

//section-hyperscalers.scss
$clr-section-hyperscalers-link: $clr-tertiary-500;

/*********************** END FUNCTIONAL CSS CARDS ***********************/

