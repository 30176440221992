@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../../common/styles/abstracts/mixins";

.section-authors {
    @include flex(column, flex-start, flex-start);
    background-color: $clr-gray-100;
    gap: 12px;

    &__people {
        gap: 40px;
        align-self: stretch;
    }
}
