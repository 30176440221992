@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/_variables-pcg";

.pagination {
  @include flex(row, center, center);
  padding: 0;
  gap: 16px;

  &__page {
    padding: 4px 7px;
    color: $clr-black;
    &--active {
      cursor: pointer;
      color: $clr-black;
      font-weight: 900;
      background-color: $clr-tertiary-500;
      padding: 4px 7px;
    }
  }

  &__previous,
  &__page,
  &__next {
    cursor: pointer;
  }

  @include mq("phone-small", max) {
    &__previous,
    &__next {
      display: none;
    }
  }
  @include mq("desktop-wide", max) {
    padding: 0;
  }

  &__dots {
    user-select: none;
  }
  &--top {
    margin-top: 0;
  }
}
