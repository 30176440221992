@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../../../common/styles/abstracts/mixins";

.author {
    @include flex(column, center, flex-start);

    @include mq("tablet") {
        justify-content: flex-start;
        flex: 1;
    }

    @include mq("desktop") {
        flex-direction: row;
    }

    &__photo-container {
        @include flex(column, center, center);
        width: 120px;
        height: 120px;
        position: relative;
        margin-bottom: 12px;

        @include mq("desktop") {
            margin-right: 20px;
            margin-bottom: 0px;
        }

        &__photo {
            border-radius: 50%;
            width: 116px;
            height: 116px;
            object-fit: cover;
            position: absolute;
            left: 0;
        }

        &__social-media-icon {
            position: absolute;
            bottom: 0px;
            right: 0px;
            border-radius: 50%;
            width: 36px;
            height: 36px;
        }
    }

    &__details-container {
        @include flex(column, center, flex-start);
        row-gap: 12px;

        &__block {
            @include flex(column, center, flex-start);
            row-gap: 4px;

            &__author-name {
                color: $clr-primary-500;
                margin: 0px;
            }

            &__author-job-title {
                color: $clr-secondary-700;
                font-weight: 600;
                text-transform: unset;
                line-height: 24px;
                margin: 0px;
            }

            &__author-info {
                @include flex(row, center, flex-start);
                gap: 6px;

                &__icon {
                    @include flex();
                    padding: 4.5px 3px;
                    flex-shrink: 0;
                }

                &__item {
                    flex: 1;
                    font-size: 14px;
                    line-height: 20px;
                    word-break: break-word;
                }
            }
        }
    }
}
