@import "../abstracts/mixins";

section,
nav,
.title,
.top-bar,
.top-bar-home {
    padding: 12px 5vw;

    @include mq("phablet", min) {
        padding: 16px 10vw;
    }

    @include mq("desktop-wide", min) {
        padding: 16px 15vw;
    }
}

section {
    @include vertical-spacing(padding, 40px);

    @include mq("tablet") {
        @include vertical-spacing(padding, 60px);
    }
}

footer {
    padding: 24px 5vw;

    @include mq("phablet", min) {
        padding: 24px 10vw;
    }

    @include mq("desktop-wide", min) {
        padding: 24px 15vw;
    }
}

.title {
    @include vertical-spacing(margin, 0);
    @include vertical-spacing(padding, 0);
}

.top-bar,
.top-bar-home {
    @include vertical-spacing(padding, 0);
}

.grid {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr;

    @include mq("tablet", min) {
        grid-gap: 24px;
    }

    &-three {
        @include mq("phablet", min) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include mq("tablet-wide", min) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &-two {
        @include mq("tablet", min) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &-two-percent {
        @include mq("tablet", min) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &-small {
        @include mq("tablet", max) {
            grid-gap: 0;

            .service-card {
                margin-top: 0;
                margin-bottom: -8px;

                h3 {
                    margin-bottom: 4px;
                }
            }
        }
    }
}

.column {
    display: flex;
    flex-direction: row;

    &-two {
        justify-content: space-between;
        align-items: flex-start;
    }
}

.gatsby-image-wrapper {
    div {
        display: none;
    }
}

.text-container {
    > * {
        width: 100%;
        max-width: 850px;
    }
}

.mb-0 {
    margin-bottom: 0;
}

.metadata_date {
    @include flex(row, space-between, flex-start);

    margin-top: 24px;

    @include mq("phone-wide", max) {
        @include flex(column, flex-start, flex-start);

        span {
            padding-bottom: 12px;
        }
    }

    span {
        color: $clr-gray-500;
    }
}

.highlighted-services {
    background-color: $clr-gray-100;
}

.faq {
    margin-bottom: 20px;
    h3 {
        margin-bottom: 20px;
    }
}

.section-insights {
    h3 {
        max-width: 670px;
        margin-bottom: 20px;
    }

    margin-bottom: 20px;
}

.service-section-clients {
    @include vertical-spacing(margin, 40px);
}
