@import "src/common/styles/abstracts/variables-pcg.scss";
@import "../../styles/abstracts/mixins";

button {
  outline: none;
  border: none;
  cursor: pointer;
}

button:lang(el) {
  font-family: "Manrope", sans-serif;
}

.btn {
  &--block {
    width: 100%;
  }

  &__primary {
    @include flex();

    font-size: 14px;
    min-height: 40px;
    padding: 10px 12px;
    color: $clr-white;
    font-weight: 800;
    text-transform: uppercase;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    z-index: 10;
    min-width: 120px;

    .before {
      background: hsla(0, 0%, 100%, 0.2);
      height: 20px;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%) scaleX(0);
      transform: translateY(-50%) scaleX(0);
      -webkit-transform-origin: left;
      transform-origin: left;
      transition: 0.5s;
    }

    @include btn-arrow();

    //KEEP THIS CODE UNTIL WE DECIDE ON THE ANIMATION
    //&:before {
    //  content: '';
    //  position: absolute;
    //  left: -90px;
    //  top: 0;
    //  height: 100%;
    //  width: 50%;
    //  background: rgba(white, .1);
    //  z-index: -1;
    //  transition: .3s ease-in all;
    //}
    //&:after {
    //  content: '';
    //  position: absolute;
    //  left: -7%;
    //  top: 5%;
    //  height: 90%;
    //  width: 5%;
    //  background: rgba(white, .6);
    //  z-index: -1;
    //  transition: .4s .02s ease-in all;
    //}
    &:hover {
      cursor: pointer;

      .before {
        transform: translateY(-50%) scaleX(0.75);
      }

      //&:before {
      //  left: 100%;
      //  width: 25%;
      //}
      //&:after {
      //  left: 100%;
      //  width: 70%;
      //}
    }

    @include mq("tablet", min) {
      font-size: 16px;
      min-height: 48px;
    }

    &--large {
      @include mq("tablet", min) {
        min-width: 248px;
      }
    }

    &--hero {
      min-width: 250px;
      margin-top: 46px;
    }

    &--anchor {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      &:visited {
        color: $clr-white;
      }
    }
  }

  &__secondary {
    font-size: 16px;
    padding: 0;
    background-color: $clr-white !important;
    position: relative;
    white-space: nowrap;
    font-weight: 700;
    color: $clr-black;

    @include btn-arrow($clr-black);

    &:hover {
      color: $clr-tertiary-500;
      @include btn-arrow($clr-tertiary-500);
    }

    &--see-all {
      font-size: 16px;
      padding: 0;
      text-transform: uppercase;
      text-decoration: underline;
      background-color: $clr-white !important;
      position: relative;
      white-space: nowrap;
      color: $clr-tertiary-500;
    }

    &--see-more {
      &::before {
        background: $clr-tertiary-500;
        content: "";
        display: block;
        height: 3px;
        right: -20px;
        position: absolute;
        top: 50%;
        width: 15px;
        transition: right 0.1s;
      }

      &::after {
        content: "";
        position: absolute;
        right: -15px;
        top: 58%;
        display: block;
        border-right: 3px solid $clr-tertiary-500;
        border-bottom: 3px solid $clr-tertiary-500;
        width: 8px;
        height: 8px;
        border-radius: 15%;
        transform: translate(50%, -50%) rotate(-45deg);
        transition: right 0.1s;
      }

      &:hover::before,
      &:hover::after {
        right: -20px;
      }
    }
  }

  &__ternary {
    @include flex(row, center, center);
    font-size: 14px;
    font-family: "Sora", sans-serif;
    background-color: $clr-white !important;
    min-height: 40px;
    min-width: 125px;
    padding: 12px;
    margin-top: 12px;
    font-weight: bold;
    color: $clr-tertiary-500;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.1s ease-in;

    @include mq("tablet", min) {
      font-size: 16px;
      min-height: 48px;
    }

    &:hover {
      background-color: $clr-tertiary-500 !important;
      color: $clr-white;
      @include btn-arrow();
    }

    &--anchor {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      &:visited {
        color: $clr-tertiary-500;

        &:hover {
          background-color: $clr-tertiary-500;
          color: $clr-white;
        }
      }
    }
  }

  &__ternary:lang(el) {
    font-family: "Manrope", sans-serif;
  }

  &__filter {
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 8px 16px;
    font-size: 16px;
    width: max-content;

    &--active_clr-filter-btn-1 {
      color: $clr-filter-btn-text !important;
      background: $clr-filter-btn-1 !important;
    }

    &--active_clr-filter-btn-2 {
      color: $clr-filter-btn-text !important;
      background: $clr-filter-btn-2 !important;
    }

    &--active_clr-filter-btn-3 {
      color: $clr-filter-btn-text !important;
      background: $clr-filter-btn-3 !important;
    }

    &__insights {
      font-family: "Sora", sans-serif;
      margin-right: 16px;
      margin-top: 0;
      margin-bottom: 12px;
      outline: none;
      color: $clr-black;
      padding-left: 0;
      border: none;
      background: unset !important;
      cursor: pointer;
      font-size: 14px;

      &--active {
        font-family: "Sora", sans-serif;
        margin-right: 16px;
        margin-top: 0;
        margin-bottom: 12px;
        outline: none;
        color: $clr-tertiary-500;
        padding-left: 0;
        border: none;
        background: unset !important;
        cursor: pointer;
        font-size: 14px;
      }

      &--active:lang(el) {
        font-family: "Manrope", sans-serif;
      }
    }

    &__insights:lang(el) {
      font-family: "Manrope", sans-serif;
    }
  }

  &__icon {
    &--next {
      height: 46px;
      width: 46px;
      position: absolute;
      right: 0;
      bottom: 6px;
      border: solid 1px $clr-tertiary-500;
      color: $clr-tertiary-500;
      cursor: pointer;
      text-align: center;

      &::before {
        content: "→";
        font-size: 20px;
      }

      @include mq("phone", max) {
        width: 40px;
        height: 40px;
      }
    }

    &--back {
      background: none;
      border: solid 1px $clr-tertiary-500;
      color: $clr-tertiary-500;
      cursor: pointer;
      height: 46px;
      width: 46px;
      margin-bottom: 24px;
      text-align: center;
      @include flex(row, center, center);

      &::before {
        content: "←";
        font-size: 20px;
      }

      @include mq("phone", max) {
        width: 40px;
        height: 40px;
      }
    }

    &--circle {
      color: $clr-white;
      border-radius: 50%;
      padding: 8px;
      width: 24px;
      height: 24px;
      position: relative;
      right: 0;

      &::before {
        content: " ";
        position: absolute;
        display: block;
        background-color: white;
        width: 2px;
        left: 11px;
        top: 3px;
        bottom: 3px;
        transform: rotate(45deg);
      }

      &::after {
        content: " ";
        position: absolute;
        display: block;
        background-color: white;
        height: 2px;
        top: 11px;
        left: 3px;
        right: 3px;
        transform: rotate(45deg);
      }

      &__insights {
        border: none;
        color: $clr-white;
        cursor: pointer;
        border-radius: 50%;
        padding: 8px;
        width: 30px;
        height: 30px;
        position: relative;
        right: 0;

        &::before {
          content: " ";
          position: absolute;
          display: block;
          background-color: $clr-btn-circle-x;
          width: 2px;
          left: 14px;
          top: 5px;
          bottom: 5px;
          transform: rotate(45deg);
        }

        &::after {
          content: " ";
          position: absolute;
          display: block;
          background-color: $clr-btn-circle-x;
          height: 2px;
          top: 14px;
          left: 5px;
          right: 5px;
          transform: rotate(45deg);
        }

        &--clear_filters {
          border: none;
          color: $clr-white;
          cursor: pointer;
          border-radius: 50%;
          padding: 8px;
          width: 25px;
          height: 25px;
          position: relative;
          right: 0;

          &::before {
            content: " ";
            position: absolute;
            display: block;
            background-color: $clr-primary-500;
            width: 1.5px;
            left: 12px;
            top: 4px;
            bottom: 4px;
            transform: rotate(45deg);
          }

          &::after {
            content: " ";
            position: absolute;
            display: block;
            background-color: $clr-primary-500;
            height: 1.5px;
            top: 12px;
            left: 4px;
            right: 4px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}

.small-cta {
  font-weight: 600;
  line-height: 20px;
  font-family: "Sora", sans-serif;

  @include mq("tablet", min) {
    line-height: 24px;
  }
}

$colors-btn-text: (
  "clr-primary-500": $clr-on-primary-500,
  "clr-secondary-500": $clr-on-secondary-500,
  "clr-secondary-700": $clr-on-secondary-700,
  "clr-tertiary-500": $clr-on-tertiary-500,
  "clr-quaternary-500": $clr-on-quaternary-500,
  "clr-link": $clr-white,
  "white": $clr-white,
  "black": $clr-black,
  "aws": $clr-on-primary-500,
  "azure": $clr-on-secondary-500,
);

$colors-btn-text-ternary: (
  "clr-primary-500": $clr-btn-ternary-text-on-clr-primary-500,
  "clr-secondary-500": $clr-btn-ternary-text-on-clr-secondary-500,
  "clr-tertiary-500": $clr-btn-ternary-text-on-clr-tertiary-500,
  "clr-quaternary-500": $clr-btn-ternary-text-on-clr-quaternary-500,
);

@each $class-name, $color-value in $colors-btn-text {
  .btn_text--#{$class-name},
  .btn_text--#{$class-name}:visited {
    color: $color-value;

    @include btn-arrow($color-value);
  }

  .btn__ternary.btn_text--#{$class-name}:hover,
  .btn__ternary.btn_text--#{$class-name}:visited:hover {
    color: $color-value;
    border: 1px solid $color-value;

    @include btn-arrow($color-value);
  }
}

@each $class-name, $color-value in $colors-btn-text-ternary {
  .btn__ternary.btn_text--#{$class-name},
  .btn__ternary.btn_text--#{$class-name}:visited {
    color: $color-value;
    border: 1px solid $color-value;

    @include btn-arrow($color-value);
  }
}
