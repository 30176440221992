@import "src/common/styles/abstracts/variables-pcg.scss";
.layout {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.outlet {
    min-height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
}
